'use client';
import 'client-only';

import { useContext } from 'react';
import { ApiClientContext } from './ApiClientContext';
import { INTERNAL__problemsImportQueryConfig } from '@features/backoffice/problems-import/_problemsImportQueryConfig';
import { INTERNAL__bankTransactionsQueryConfig } from '@features/backoffice/accounting/transactions/api/_bankTransactionsQueryConfig';
import { INTERNAL__affiliatesQueryConfig } from '@features/affiliates/api/_affiliatesQueryConfig';
import { ITENRAL__admissionScoreCalculatorsQueryConfig } from '@features/webshop/admission-score-calculators/api/_admissionScoreCalculatorsQueryConfig';
import { INTERNAL__revenueQueryConfig } from '@features/backoffice/accounting/revenue/api/_revenueQueryConfig';
import { INTERNAL__coursesQueryConfig } from '@features/frontoffice/courses/data/_coursesQueryConfig';
import { INTERNAL__quizzesQueryConfig } from '@features/frontoffice/quiz/api/_quizzesQueryConfig';

function useApiClient() {
	const {
		authApiClient,
		webshopApiClient,
		frontofficeApiGen,
		backofficeApiClient,
		sharedApiClient,
		webhooksApiClient,
		apiFetch,
		session,
	} = useContext(ApiClientContext);

	if (
		!authApiClient ||
		!webshopApiClient ||
		!frontofficeApiGen ||
		!backofficeApiClient ||
		!sharedApiClient ||
		!webhooksApiClient ||
		!apiFetch
	) {
		throw new Error('useApiClient must be used within ApiClientProvider');
	}

	const queries = {
		courses: INTERNAL__coursesQueryConfig(apiFetch),
		problemImports: INTERNAL__problemsImportQueryConfig(backofficeApiClient),
		bankTransactions: INTERNAL__bankTransactionsQueryConfig(apiFetch),
		affiliates: INTERNAL__affiliatesQueryConfig(apiFetch),
		admissionScoreCalculators: ITENRAL__admissionScoreCalculatorsQueryConfig(apiFetch),
		revenue: INTERNAL__revenueQueryConfig(apiFetch),
		quizzes: INTERNAL__quizzesQueryConfig(apiFetch),
	};

	return {
		authApiClient,
		webshopApiClient,
		frontofficeApiGen,
		backofficeApiClient,
		sharedApiClient,
		webhooksApiClient,
		apiFetch,
		session,
		queries,
	};
}

export default useApiClient;
